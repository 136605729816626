import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Magnifier from "react-magnifier";
import Button from "@material-ui/core/Button";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import ReactDOM from "react-dom";

const ImageDialog = (props) => {
  const { open, imageUrl, onClose } = props;
  return (
    <>
      <>{
        open &&
        <Lightbox
          image={imageUrl} onClose={onClose}
        />
      }</>
    </>);
};

export default ImageDialog;
