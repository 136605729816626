import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";

const PagePaper = withStyles({
  root: {
    padding: "10px",
    margin: "20px",
  },
})(Paper);

export default PagePaper;