import { combineReducers } from "redux";

import todosReducer from "./features/todos/todosSlice";
import filtersReducer from "./features/filters/filtersSlice";
import authReducer from "store/slicers/auth/auth";
import drawerReducer from "store/slicers/drawer";
import languageReducer from "store/slicers/language";
import customerClaimReducer from "store/slicers/customer/claim";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  todos: todosReducer,
  filters: filtersReducer,
  auth: authReducer,
  drawer: drawerReducer,
  language: languageReducer,
  customerClaims: customerClaimReducer,
});

export default rootReducer;
