import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import api from "plugins/api";

export const fetchCustomerClaim = createAsyncThunk(
  "customer/findCustomerClaim",
  async (params, thunkAPI) => {
    const response = await api.get(
      `customers/${params.customer_id}/claims/${params.claim_id}`
    );

    return response.data;
  }
);

export const fetchdownloadAllFiles = createAsyncThunk(
  "claim/downloadAllFiles",
  async (params, thunkAPI) => {
    const response = await api.get(
      `customers/${params.customer_id}/claims/${params.claim_id}/uploads/download`
    );

    // return fileDownload(response.data, filename)
    return response.data;
  }
);

const entityAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

export const entitySelectors = entityAdapter.getSelectors(
  (state) => state.customerClaims
);

const { actions, reducer } = createSlice({
  name: "customer/claim",
  initialState: entityAdapter.getInitialState({
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchCustomerClaim.fulfilled]: (state, action) => {
      entityAdapter.upsertOne(state, action);
    },
    [fetchCustomerClaim.pending]: (state, action) => {},
    [fetchCustomerClaim.rejected]: (state, action) => {},
  },
});

export default reducer;
