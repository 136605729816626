import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "plugins/api";

export const logIn = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    const response = await axios.post("/login_check", {
      username: credentials.email,
      password: credentials.password,
    });

    return response.data;
  }
);

export const ssoLogIn = createAsyncThunk(
  "sso/login",
  async (credentials, thunkAPI) => {
    const response = await axios.post("/login_check", {
      username: credentials.email,
      password: credentials.password,
      restrictedClaim: credentials.restrictedClaim
    });
    localStorage.setItem('callback_url', credentials.callbackUrl);
    localStorage.setItem('i18nextLng',credentials.lang)
    return response.data;
  }
);


const cleanUpAuthState = (state) => {
  state.loggedIn = false;
  state.token = null;
  state.user = null;
  state.userRole = null;
  state.false = false;

  localStorage.removeItem('access_token');
  localStorage.removeItem('callback_url');
  localStorage.removeItem('i18nextLng');
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggedIn: false,
    token: null,
    user: null,
    userRole: null,
    sso : false,
  },
  reducers: {
    logOut(state, action) {
      cleanUpAuthState(state);
    },
    pickRole(state, action) {
      state.userRole = action.payload;
    },
    clearRole(state) {
      state.userRole = null;
    }
  },
  extraReducers: {
    [logIn.rejected]: (state, action) => {
      cleanUpAuthState(state);
    },
    [logIn.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem('access_token', state.token);
      window.location.reload();
    },
    [logIn.pending]: (state, action) => {
      cleanUpAuthState(state);
    },
    [ssoLogIn.rejected]: (state, action) => {
      cleanUpAuthState(state);
    },
    [ssoLogIn.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.userRole = {"role":{"name":"Yönetici","code":"ACL_ROLE_CUSTOMER_CALL_CENTER_AGEN","uid":"4863910517","id":"01EZPKZ5JTRK96NR9A2405GAXX"}};
      state.sso = true;
      localStorage.setItem('access_token', state.token);
      window.location.assign('/customers/'+state.user.restrictedCustomer+'/claims/'+state.user.restrictedClaim);
    },
    [ssoLogIn.pending]: (state, action) => {
      //cleanUpAuthState(state);
    },
  },
});

export const { logOut, pickRole, clearRole } = authSlice.actions;

export default authSlice.reducer;
