import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

const languageReducer = createSlice({
  name: "language",
  initialState: {
    code: localStorage.getItem('i18nextLng') || "tr",
  },
  reducers: {
    change(state, action) {
      localStorage.setItem('i18nextLng', action.payload);
      i18n.changeLanguage(action.payload);
      return { ...state, code: action.payload };
    },
  },
});

export const { change } = languageReducer.actions;

export default languageReducer.reducer;
