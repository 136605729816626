import { createSlice } from "@reduxjs/toolkit";

const drawerReducer = createSlice({
  name: "drawer",
  initialState: {
    open: true
  },
  reducers: {
    toggle(state, action) {
      state.open = !state.open;
    },
  },
});

export const { toggle } = drawerReducer.actions;

export default drawerReducer.reducer;