import {Button, Grid, TextField, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import api from "../../plugins/api";
import {Alert} from "@material-ui/lab";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginCode = (props) => {
  const {onSuccess} = props;
  const [loginCode, setLoginCode] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const check = async () => {
    setChecking(true);
    const result = await api.post('/me/check-login-code', {code: loginCode});
    setChecking(false);

    if (result.data.status === 'required') {
      setError('é');
    }

    if (result.data.status === 'not-required') {
      onSuccess();
    }
  }

  return <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
      {error !== '' && <Alert severity={"warning"}>{t("loginCode.alert")}</Alert>}
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("loginCode.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant={"outlined"}
          placeholder={t("loginCode.placeholder")}
          onChange={
            (e) => {setLoginCode(e.target.value)}
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          color={"primary"}
          disabled={checking}
          onClick={check}
        >{t("loginCode.button")}</Button>
      </Grid>
    </Grid>
  </>
}

export default LoginCode;