import React, {Suspense, lazy, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Routes, Route, useNavigate} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import api from "./plugins/api";
import LoginCode from "./components/Auth/LoginCode";
import AuthLayout from "./layout/AuthLayout";
import AllUploads from "./view/Customer/Claims/Claim/AllUploads/AllUploads";
import i18n from "i18next";

const Sso = lazy(() => import("components/Auth/Sso"));
const SignIn = lazy(() => import("components/Auth/SignIn"));
const PickRole = lazy(() => import("components/Auth/PickRole"));
const DefaultLayout = lazy(() => import("layout/DefaultLayout/DefaultLayout"));
const AdminIndex = lazy(() => import("view/Admin/Index/Index"));
const AdminCustomers = lazy(() => import("view/Admin/Customers/Customers"));
const AdminUsers = lazy(() => import("view/Admin/Users/Users"));
const AdminTaxonomies = lazy(() => import("view/Admin/Taxonomies/Taxonomies"));
const AdminTerms = lazy(() => import("view/Admin/Terms/Terms"));
const AdminUserRoles = lazy(() => import("view/Admin/UserRoles/UserRoles"));
const AdminRepairShops = lazy(() => import("view/Admin/RepairShops/RepairShops"));
const AdminRepairShopRequests = lazy(() => import("view/Admin/RepairShops/Requests/Requests"));
const CustomerVideoCalls = lazy(() =>
  import("view/Customer/VideoCalls/VideoCalls"),
);
const CustomerSelfAdjusts = lazy(() =>
  import("view/Customer/SelfAdjusts/SelfAdjust"),
);
const CustomerIndex = lazy(() => import("view/Customer/Index/Index"));
const CustomerClaims = lazy(() => import("view/Customer/Claims/Claims"));
const CustomerCreateClaim = lazy(() => import("view/Customer/Claims/CreateClaim/CreateClaim"));
const CustomerClaim = lazy(() => import("view/Customer/Claims/Claim/Claim"));
const CustomerClaim_CarDamageClaim_Details = lazy(() =>
  import("view/Customer/Claims/Claim/CarDamageClaim/Details/Details"),
);
const CustomerClaim_CarDamageClaim_Details_Info = lazy(() =>
  import("view/Customer/Claims/Claim/CarDamageClaim/Details/Info/Info"),
);
const CustomerClaim_VideoCalls = lazy(() =>
  import("view/Customer/Claims/Claim/VideoCalls/VideoCalls"),
);
const CustomerClaim_VideoCalls_VideoCall = lazy(() =>
  import("view/Customer/Claims/Claim/VideoCall/VideoCall"),
);
const CustomerClaim_Catalog = lazy(() =>
  import("view/Customer/Claims/Claim/CarDamageClaim/Catalog"),
);
const CustomerClaim_AllUploads = lazy(() =>
  import("view/Customer/Claims/Claim/AllUploads/AllUploads"),
);
const CustomerClaim_Selfadjusts = lazy(() =>
  import("view/Customer/Claims/Claim/Selfadjusts/Selfadjusts"),
);
const CustomerClaim_Selfadjusts_Index = lazy(() =>
  import("view/Customer/Claims/Claim/Selfadjusts/Index"),
);
const CustomerClaim_Selfadjust = lazy(() =>
  import("view/Customer/Claims/Claim/Selfadjust/Selfadjust"),
);
const CustomerClaim_Auctions = lazy(() =>
  import("view/Customer/Claims/Claim/Auctions/Index"),
);
const CustomerClaim_PhotoScans = lazy(() =>
  import("view/Customer/Claims/Claim/PhotoScans/PhotoScans"),
);
const CustomerClaim_PhotoScans_PhotoScan = lazy(() =>
  import("view/Customer/Claims/Claim/PhotoScans/PhotoScan"),
);

const Customer_Settings_AztPaint = lazy(() =>
  import("view/Customer/Settings/AztPaint/AztPaint"),
);

const Customer_Settings_ScalePrices = lazy(() =>
  import("view/Customer/Settings/ScalePrices/ScalePrices"),
);

const Customer_Settings_ScalePrices_Prices = lazy(() =>
  import("view/Customer/Settings/ScalePrices/Prices"),
);

const Customer_Settings_RepairShops = lazy(() =>
  import("view/Customer/Settings/RepairShops/RepairShops"),
);

const Customer_Settings_Auctions = lazy(() =>
  import("view/Customer/Settings/Auctions/Index"),
);

const Customer_Reports_SektorMaliyetRaporu = lazy(() =>
  import("view/Customer/Reports/SektorMaliyetRaporu"),
)

const Customer_Reports_MusteriDurumGenisRaporu = lazy(() =>
  import("view/Customer/Reports/MusteriDurumGenisRaporu"),
)
const InspectlabsMappings = lazy(() =>
  import("view/Admin/Cupi/Cupi"),
)
const Customer_Reports_ParcaYapayZekaKullaniciOraniRaporu = lazy(() =>
  import("view/Customer/Reports/ParcaYapayZekaKullaniciOraniRaporu"),
)

const IndexPage = () => {
  const pickedRole = useSelector((state) => state.auth.userRole);
  const navigate = useNavigate();

  useEffect(() => {
    if (pickedRole) {
      navigate("/customers/" + pickedRole.customer.id);
    }
  });

  return <></>;
};

function App() {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const statecheck = useSelector((state) => state.auth);
  const isSso = useSelector((state) => state.auth.sso);
  const pickedRole = useSelector((state) => state.auth.userRole);
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [loginCodeStatus, setLoginCodeStatus] = useState('waiting');

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getLoginCodeStatus = async () => {
    if (user) {
      i18n.changeLanguage(localStorage.getItem('i18nextLng') || 'en');
      const result = await api.get('/me/login-code-status');
      setLoginCodeStatus(result.data.status);
    }
    setLoading(false);
  }

  useEffect(() => {
    getLoginCodeStatus()
  }, [])

  if (loading) {
    return <CircularProgress/>
  }

  if (loginCodeStatus === 'required') {
    return (
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <AuthLayout>
            <LoginCode onSuccess={getLoginCodeStatus}/>
          </AuthLayout>
        </Suspense>
      </div>
    );
  }

  if (!loggedIn && !isSso) {
    return (
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path={"/"} element={<SignIn/>}/>
            <Route path="/sso/:restrictedClaim" element={<Sso/>}/>
          </Routes>
        </Suspense>
      </div>
    );
  }

  if (loggedIn && !pickedRole && !isSso && !user.isAdmin ) {
    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <div className="App">
            <Route path="/pick-role" element={<PickRole/>}/>
          </div>
        </Suspense>
      </>
    );
  }

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {user && user.isAdmin && (
            <Route path="/admin" element={<DefaultLayout/>}>
              <Route path="/" element={<AdminIndex/>}/>
              <Route path="/customers" element={<AdminCustomers/>}/>
              <Route path="/users" element={<AdminUsers/>}/>
              <Route path="/taxonomies" element={<AdminTaxonomies/>}/>
              <Route path="/terms" element={<AdminTerms/>}/>
              <Route path="/user-roles" element={<AdminUserRoles/>}/>
              <Route path="/repair-shops" element={<AdminRepairShops/>}/>
              <Route path="/repair-shops/requests" element={<AdminRepairShopRequests/>}/>
              <Route path="/cupi/cupi" element={<InspectlabsMappings/>}/>
            </Route>
          )}
          <Route path="/sso/:restrictedClaim" element={<Sso/>}/>
          {pickedRole ? (
            <>
              <Route path={"/"} element={<IndexPage/>}/>
              <Route
                path="/customers/:customer_id/claims/:claim_id/catalog"
                element={<CustomerClaim_Catalog/>}
              />
              <Route path="/customers/:customer_id" element={<DefaultLayout/>}>
                <Route path="/" element={<CustomerIndex/>}/>
                <Route path="reports/sektor-maliyet-raporu" element={<Customer_Reports_SektorMaliyetRaporu/>}/>
                <Route path="reports/musteri-durum-genis-raporu" element={<Customer_Reports_MusteriDurumGenisRaporu/>}/>
                <Route path="reports/parca-yapay-zeka-kullanici-orani-raporu" element={<Customer_Reports_ParcaYapayZekaKullaniciOraniRaporu/>}/>

                <Route path="video-calls" element={<CustomerVideoCalls/>}/>
                <Route path="self-adjusts" element={<CustomerSelfAdjusts/>}/>
                <Route
                  path="/settings/scale-prices"
                  element={<Customer_Settings_ScalePrices/>}
                />
                <Route
                  path="/settings/azt-paint"
                  element={<Customer_Settings_AztPaint/>}
                />
                <Route
                  path="/settings/auctions"
                  element={<Customer_Settings_Auctions/>}
                />
                <Route
                  path="/settings/scale-prices/:scalePricesRule_id/prices"
                  element={<Customer_Settings_ScalePrices_Prices/>}
                />
                <Route
                  path="/settings/repair-shops"
                  element={<Customer_Settings_RepairShops/>}
                />
                <Route path="/claims" element={<CustomerClaims/>}/>
                <Route path="/create-claim" element={<CustomerCreateClaim/>}/>
                <Route path="/claims/:claim_id" element={<CustomerClaim/>}>
                  <Route
                    path="/"
                    element={<CustomerClaim_CarDamageClaim_Details/>}
                  />
                  <Route
                    path="/claim-info"
                    element={<CustomerClaim_CarDamageClaim_Details_Info/>}
                  />
                  <Route
                    path="/video-calls"
                    element={<CustomerClaim_VideoCalls/>}
                  >
                    <Route
                      path="/:videoCall_id"
                      element={<CustomerClaim_VideoCalls_VideoCall/>}
                    />
                  </Route>
                  <Route path="/auctions" element={<CustomerClaim_Auctions/>}/>
                  <Route path="/all-uploads" element={<CustomerClaim_AllUploads/>}/>
                  <Route
                    path={"/photo-scans"}
                    element={<CustomerClaim_PhotoScans/>}
                  >
                    <Route
                      path="/:photoScan_id"
                      element={<CustomerClaim_PhotoScans_PhotoScan/>}
                    />
                  </Route>
                  <Route
                    path="/selfadjusts"
                    element={<CustomerClaim_Selfadjusts/>}
                  >
                    <Route
                      path=""
                      element={<CustomerClaim_Selfadjusts_Index/>}
                    />
                    <Route
                      path="/:selfadjust_id"
                      element={<CustomerClaim_Selfadjust/>}
                    />
                  </Route>
                </Route>
              </Route>
            </>
          ) : (
            <div className="App">
              <Route path="/pick-role" element={<PickRole/>}/>
            </div>
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
