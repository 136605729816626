import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, Grid, LinearProgress, MenuItem, Select} from "@material-ui/core";
import {useParams} from "react-router-dom";
import api, {apiEndpoint} from "../../../../../plugins/api";
import PagePaper from "../../../../../components/PagePaper";
import ImageDialog from "../../../../../components/ImageDialog";
import {useSnackbar} from "notistack";
import {Dashboard, useUppy} from "@uppy/react";
import Uppy from "@uppy/core";
import Turkish from "@uppy/locales/lib/tr_TR";
import XHRUpload from "@uppy/xhr-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import {useTranslation} from "react-i18next";

const EditMediaTypeComponent = (props) => {
  const {mediaTypes, onMediaTypeSelect, defaultValue} = props;

  const onChange = (event) => {
    onMediaTypeSelect(event.target.value);
  };

  return (
    <>
      <Select fullWidth={true} onChange={onChange} value={defaultValue}>
        {mediaTypes.map((mediaType) => {
          return (
            <MenuItem value={mediaType.id}>
              {mediaType.parent.name} - {mediaType.name}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const Item = (props) => {
  const {item, onItemClick, mediaTypes, selectedItemIds, onToggle, onMediaTypeSelect} = props;

  return <Grid item xs={6} md={3}>
    {item.upload.mimeType === "image/jpeg" && (
      <img
        src={item.upload.url}
        onClick={() => {
          onItemClick(item.upload);
        }}
        style={{maxWidth: "100%"}}
      />
    )}
    {item.upload.mimeType !== "image/jpeg" && (
      <img
        src={"/ui/img/document.png"}
        onClick={() => {
          onItemClick(item.upload);
        }}
        style={{maxWidth: "100%"}}
      />
    )}
    <Box display="flex">
      <Box>
        <Checkbox
          color={"primary"}
          onChange={() => {
            onToggle(item.upload.id);
          }}
          checked={selectedItemIds.includes(item.upload.id)}
        />
      </Box>
      <Box flexGrow={1}>
        <EditMediaTypeComponent
          mediaTypes={mediaTypes}
          defaultValue={item.mediaType?.id}
          onMediaTypeSelect={(mediaType_id) => {
            onMediaTypeSelect(item.upload.id, mediaType_id)
          }}
        />
      </Box>
      <Box></Box>
    </Box>
  </Grid>
};

const ItemsGrid = (props) => {
  const {items, deleteItems, onMediaTypeSelect} = props;
  const [imageDialogVisible, setImageDialogVisible] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState(null);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const {t} = useTranslation();

  const loadMediaTypes = async () => {
    const result = await api.get("/generic/upload-media-types");
    setMediaTypes(result.data);
  };

  useEffect(() => {
    loadMediaTypes();
  }, []);

  const onItemClick = (upload) => {
    if (upload.mimeType === "image/jpeg") {
      setImageDialogVisible(true);
      setSelectedUpload(upload);
      return;
    }

    const newWindow = window.open(upload.url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  const toggleSelectedItemId = (item_id) => {
    let newArray = [...selectedItemIds, item_id];
    if (selectedItemIds.includes(item_id)) {
      newArray = newArray.filter(id => id !== item_id);
    }
    setSelectedItemIds(newArray);
  }

  return <>
    {imageDialogVisible && (
      <ImageDialog
        open={imageDialogVisible}
        imageUrl={selectedUpload.url}
        onClose={() => {
          setImageDialogVisible(false);
        }}
      />
    )}
    <Box mb={3}>
      {selectedItemIds.length > 0 &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            deleteItems(selectedItemIds)
          }}>
          {t("button.deleteSelect")}
        </Button>
      }
    </Box>
    <Grid container spacing={3}>
      {items.map((item) => {
        return <Item
          item={item}
          onItemClick={onItemClick}
          mediaTypes={mediaTypes}
          selectedItemIds={selectedItemIds}
          onToggle={toggleSelectedItemId}
          key={item.upload.id}
          onMediaTypeSelect={onMediaTypeSelect}
        />
      })}
    </Grid>
  </>
}

const AllUploads = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const routeParams = useParams();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const uppy = useUppy(() => {
    return new Uppy({locale: Turkish}).use(XHRUpload, {
      endpoint: `${apiEndpoint}customers/${routeParams.customer_id}/claims/${routeParams.claim_id}/uploads/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  });

  const loadItems = async () => {
    setLoading(true);
    const result = await api.get(`/customers/${routeParams.customer_id}/claims/${routeParams.claim_id}/all-uploads`)
    setItems(result.data);
    setLoading(false);
  };

  const deleteItems = async (item_ids) => {
    const snackbarKey = enqueueSnackbar(t("snackbar.deleting"));
    const result = await api.delete(
      `/customers/${routeParams.customer_id}/claims/${routeParams.claim_id}/all-uploads`,
      {data: item_ids}
    )
    closeSnackbar(snackbarKey);
    await loadItems();
  }

  const updateMediaType = async (upload_id, mediaType_id) => {
    const snackbarKey = enqueueSnackbar(t("snakbar.save"));
    const result = await api.post(
      `/customers/${routeParams.customer_id}/claims/${routeParams.claim_id}/update-media-type`,
      {upload_id, mediaType_id}
    )
    closeSnackbar(snackbarKey);
    //await loadItems();
  }

  useEffect(() => {
    loadItems()
  }, []);

  if (loading) {
    return <LinearProgress/>
  }

  return <>
    <PagePaper>
      <Dashboard
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        height={200}
        width={500}
        doneButtonHandler={loadItems}
      />
      <Box m={3}/>
      <ItemsGrid items={items} deleteItems={deleteItems} onMediaTypeSelect={updateMediaType}/>
    </PagePaper>
  </>
}

export default AllUploads;